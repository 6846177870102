
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { data } from './dataRenders';
import ViewRendersMenu from './ViewRendersMenu';
import ViewEnlargedDragger from '../enlargedDragger/ViewEnlargedDragger';


export default function ViewRenders () {

    const [ enlargedState, setEnlargedState ] = useState( { selectedIndex: 0, isActive: false } );
    const onItemSelected = (index) => {
        const newIndex = index;
        setEnlargedState( { selectedIndex: newIndex, isActive: true } );
    }
    const onItemClosed = (item) => {
        setEnlargedState( { selectedIndex: null, isActive: false } );
    }

    const enlargementsQuery = useStaticQuery(graphql`
	{
		allFile(filter: {relativeDirectory: {eq: "renders"}, base: {regex: "/enlargement/"}}) {
			edges {
				node {
					id
					base
					childImageSharp {
						fixed(width: 1480, height: 834) {
                            ...GatsbyImageSharpFixed
                        }
					}
				}
			}
		}
	}
    `);
    
    const getImgNodes = () => { //use the retrieved file names to match the data up and assign the nodes for Gatsby IMG
        const imagesList = enlargementsQuery.allFile.edges;
        data.forEach( item => {
            const matchedImgNode = imagesList.find( img => img.node.base === item.enlargedSrc ); 
            item.enlargedNode = matchedImgNode.node;
        });
    }
    getImgNodes();

    return (
        <>
            <ViewRendersMenu onItemSected={ onItemSelected } />
            <ViewEnlargedDragger data={ data } enlargedState={ enlargedState } onItemClosed={ onItemClosed } /> 
        </>
    );

}