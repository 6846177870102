
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { data } from './dataRenders';
import ButtonRendersItem from './ButtonRendersItem';


export default function ViewRendersMenu ( { onItemSected } ) {

    const tmbsQuery = useStaticQuery(graphql`
	{
		allFile(filter: {relativeDirectory: {eq: "renders"}, base: {regex: "/tmb/"}}) {
			edges {
				node {
					id
					base
					childImageSharp {
						fluid {
                            ...GatsbyImageSharpFluid
                        }
					}
				}
			}
		}
	}
    `);
    
    const getImgNodes = () => { //use the retrieved file names to match the data up and assign the nodes for Gatsby IMG
        const imagesList = tmbsQuery.allFile.edges;
        data.forEach( item => {
            const matchedImgNode = imagesList.find( img => img.node.base === item.btnSrc ); 
            item.btnNode = matchedImgNode.node;
        });
    }
    getImgNodes();

    const getButtons = () => {
        const buttons = data.map( (item, index) => 
            <ButtonRendersItem key={ index } index={ index } item={ item } selectItem={ onItemSected } />
        );
        return buttons;
    }

    return (
        <nav>
            { getButtons() }
        </nav>
    );

}