import React from 'react';
import SEO from '../components/seo/SEO';
import ViewRenders from '../components/renders/ViewRenders';


export default function Renders () { 

  return (
    <>
      <SEO title='Renders' />
      <ViewRenders />
    </>
  );

}
