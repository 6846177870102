
export const data = [
    { 
        title: '',
        description: '',
        btnXPos: 100,
        btnYPos: 204,
        btnWidth: 554, 
        bthHeight: 240,
        btnSrc: 'img--render-1-tmb.jpg',
        btnNode: null,
        enlargedSrc: 'img--render-1-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: '',
        description: '',
        btnXPos: 100,
        btnYPos: 472,
        btnWidth: 554, 
        bthHeight: 240,
        btnSrc: 'img--render-2-tmb.jpg',
        btnNode: null,
        enlargedSrc: 'img--render-2-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: '',
        description: '',
        btnXPos: 100,
        btnYPos: 740,
        btnWidth: 554, 
        bthHeight: 240,
        btnSrc: 'img--render-3-tmb.jpg',
        btnNode: null,
        enlargedSrc: 'img--render-3-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: '',
        description: '',
        btnXPos: 682,
        btnYPos: 204,
        btnWidth: 554, 
        bthHeight: 374,
        btnSrc: 'img--render-4-tmb.jpg',
        btnNode: null,
        enlargedSrc: 'img--render-4-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: '',
        description: '',
        btnXPos: 682,
        btnYPos: 608,
        btnWidth: 554, 
        bthHeight: 374,
        btnSrc: 'img--render-5-tmb.jpg',
        btnNode: null,
        enlargedSrc: 'img--render-5-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: '',
        description: '',
        btnXPos: 1266,
        btnYPos: 204,
        btnWidth: 554, 
        bthHeight: 374,
        btnSrc: 'img--render-6-tmb.jpg',
        btnNode: null,
        enlargedSrc: 'img--render-6-enlargement.jpg',
        enlargedNode: null
    },
    { 
        title: '',
        description: '',
        btnXPos: 1266,
        btnYPos: 608,
        btnWidth: 554, 
        bthHeight: 374,
        btnSrc: 'img--render-7-tmb.jpg',
        btnNode: null,
        enlargedSrc: 'img--render-7-enlargement.jpg',
        enlargedNode: null
    }
];